<template>
  <div
    v-if="renderComponent"
    class="survey"
  >
    <ValidationProvider
      name="WpbotSurveyQ1"
      rules="required"
      v-slot="{ errors }"
    >
      <div class="question">
        <div>
          <label>Para iade kampanyamıza katıldığınız markalardan birini seçer misiniz?</label>
          <v-select
            v-model="selectedBrand"
            :options="brands"
            :clearable="false"
            :searchable="false"
            :clear-search-on-select="true"
            placeholder="Seçim yapınız"
          >
          </v-select>
        </div>
        <div
          class="validation-error"
          v-if="errors.length"
        >
          {{ errors[0] }}
        </div>
      </div>
    </ValidationProvider>
    <ValidationProvider
      name="WpbotSurveyQ2"
      rules="required"
      v-slot="{ errors }"
    >
      <div class="question">
        <div>
          <label>Seçtiğin markayı son 12 ay içerisinde kullandınız mı?</label>
          <v-select
            v-model="optionSelected"
            :options="[
              { value: 'no', text: 'Hayır' },
              { value: 'yes', text: 'Evet' }
            ]"
            :reduce="option => option.value"
            label="text"
            value="value"
            :clearable="false"
            :searchable="false"
            :clear-search-on-select="true"
            placeholder="Seçim yapınız"
          >
          </v-select>
        </div>
        <div
          class="validation-error"
          v-if="errors.length"
        >
          {{ errors[0] }}
        </div>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: "WhatsappBotSurvey",
  data () {
    return {
      selectedBrand: "",
      optionSelected: "",
      brands: [
        "Gillette",
        "Gillette Venüs",
        "Orkid",
        "Prima",
        "Ariel / Alo",
        "Fairy",
        "Pantene",
        "Head & Shoulders",
        "Oral-B",
        "Old Spice / Herbal Essences"
      ],
      renderComponent: false,
      showOnCampaigns: [
        "wpbot-blackfriday",
        "wpbot-premium",
        "wpbot-pantene",
        "wpbot-toz"
      ]
    }
  },
  props: {
    successAction: {
      type: [Object, Array],
      required: true
    }
  },
  beforeMount () {
    const campaignId = this.$route.query.campaignId
    this.renderComponent = campaignId && this.showOnCampaigns.includes(campaignId)
    this.$eventHub.$on("sendWpbotSurvey-event", this.sendSurvey)
  },
  computed: {
    ...mapGetters(["campaignId", "customerToken"])
  },
  methods: {
    ...mapActions(["handleError"]),
    sendSurvey () {
      if (this.renderComponent) {
        const payload_Q1 = {
          campaignId: this.campaignId,
          customerToken: this.customerToken,
          selectedBrand: this.selectedBrand
        }
        axios
          .post("https://js-backend.justsnap.eu/api/dashboard/survey/brandSelection", payload_Q1)
          .then(res => {
            if (res.data.response) {
              const payload_Q2 = {
                campaignId: this.campaignId,
                customerToken: this.customerToken,
                optionSelected: this.optionSelected
              }
              axios
                .post("https://js-backend.justsnap.eu/api/dashboard/survey/brandConfirmation", payload_Q2)
                .then(res => {
                  if (res.data.response) {
                    const fn = this.successAction.fn
                    const params = this.successAction.params
                    this[fn](params, this)

                    this.$eventHub.$emit("done-event-trigger", {
                      parent: this,
                      status: true
                    });
                  } else {
                    this.handleException(res.data)
                  }
                })
                .catch(err => {
                  this.handleException(err.response.data)
                })
            } else {
              this.handleException(res.data)
            }
          })
          .catch(err => {
            this.handleException(err.response.data)
          })
      } else {
        const fn = this.successAction.fn
        const params = this.successAction.params
        this[fn](params, this)
      }
    },
    handleException (e) {
      this.handleError({ ...e })
      this.$eventHub.$emit("done-event-trigger", {
        parent: this,
        status: false
      })
    }
  },
  beforeDestroy () {
    this.$eventHub.$off("sendWpbotSurvey-event")
  }
}
</script>

<style lang="scss" scoped>
.survey {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 0;
  margin-top: 0;

  .question {
    position: relative;
  }
}
</style>