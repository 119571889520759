import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import i18n from './translate'
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import de from 'vee-validate/dist/locale/de.json';
import ro from 'vee-validate/dist/locale/ro.json';
import gr from 'vee-validate/dist/locale/el.json';
import hu from 'vee-validate/dist/locale/hu.json';
import cs from 'vee-validate/dist/locale/cs.json';
import sk from 'vee-validate/dist/locale/sk.json';
import pl from 'vee-validate/dist/locale/pl.json';

const ibantools = require('ibantools');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('mandatory', {
  ...rules['required']
})

// PLZ for 4 and 5 digits
extend('plz', {
  validate (value, digits) {
    const regex = digits == 5 ? /^(\d{5})?$/ : /^(\d{4})?$/;
    return (regex.test(value));
  }
});

extend('postal', {
  params: ['land'],
  validate (value, { land }) {
    const regex = land == "Germany" ? /^(\d{5})?$/ : /^(\d{4})?$/;
    return (regex.test(value));
  }
});

extend('confirmPass', {
  params: ['pass'],
  validate (value, { pass }) {
    return value === pass
  }
})
extend('hausnr', {
  ...rules['integer']
})
extend('email', {
  ...rules['email']
})
extend('password', {
  validate: value => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d\W]{8,}$/.test(value)
})
extend('iban', {
  validate: (value, params) => {
    const country = params[0] || ''
    value = country + value
    let isnum = /^\d+$/.test(value.substring(2));
    if (!isnum && country == "GR") {
      return false
    }
    return ibantools.isValidIBAN(value)
  }
});

extend('ibanBank', {
  validate: (value, params) => {
    const country = params[0] || ''
    const validCountries = {
      'GR': ['011', '014', '017', '026']
    }
    value = country + value
    if (!country) return true
    else {
      return validCountries[country].includes(value.substring(4, 7))
    }
  }
});
extend('bic', {
  validate: value => !!ibantools.isValidBIC(value)
});
extend('sepa', {
  validate: (value) => {
    const countryCode = value.substring(2, 0)
    const countToIgnore = ['GB', "LI"]
    return ibantools.isSEPACountry(countryCode) && !countToIgnore.includes(countryCode)
  }
});

extend('ibanName', {
  validate: value => {
    return /^(?:(?:[a-zA-Z]{2,})|(?:[a-zA-Z]{1}[a-zA-Z]{0,}\s?-?[a-zA-Z]{2,}))$/.test(value);
  }
});

extend('ibanNameWpbot', {
  validate: value => {
    return /^(?:(?:[a-zA-Z]{2,})|(?:[a-zA-Z]{1}[a-zA-Z]{0,}\s?-?[a-zA-Z]{2,})|(?:[a-zA-Z]{1}[a-zA-Z]{0,}\s?-?[a-zA-Z]{1,}\s?-?[a-zA-Z]{1,}))$/.test(value);
  }
});
// extend('ibanName', {
//   validate: value => {
//     return /^(?:(?:[a-zA-Z]{2,})|(?:[a-zA-Z]{1}[a-zA-Z]{0,}\s[a-zA-Z]{2,}))$/.test(value);
//   }
// });

extend('phoneNumber', {
  ...rules['integer']
})
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule] // copies rule configuration
    // message: wihtCustomMessages[rule] // assign message
  });
});


localize({
  en: {
    messages: en.messages
  },
  de: {
    messages: de.messages
  },
  ro: {
    messages: ro.messages
  },
  el: {
    messages: gr.messages
  },
  hu: {
    messages: hu.messages
  },
  cs: {
    messages: cs.messages
  },
  sk: {
    messages: sk.messages
  },
  pl: {
    messages: pl.messages
  }
});

let LOCALE = i18n.locale;
localize(LOCALE);

// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, "locale", {
  get () {
    return LOCALE
  },
  set (val) {
    LOCALE = val
    localize(val);
  }
});
