import axios from 'axios'
import i18n from '../translate'
import isTester from '../mixins/isTester';

const devDomains = {
  'localhost:8080': true,
  'localhost:8081': true,
  'pg-tr-cb.justsnap.eu': true,
  'pg-de-cb.justsnap.eu': true,
  'pg-ro-cb.justsnap.eu': true,
  'pg-gr-cb.justsnap.eu': true,
  'pg-hu-cb.justsnap.eu': true,
}


const url = window.location.href.replace(/(^\w+:|^)\/\//, '').split('/')[0];
let APIUrl = devDomains[url] ? "https://justsnap-backend-dev.justsnap.eu" : "https://justsnap-backend.justsnap.eu"

// Check if the URL is for the WPBot
const path = window.location.pathname.split('/').filter(Boolean)
if (path.includes('wpbot')) APIUrl = "https://wp-turkiye.jsnp.io/api/cbot"

export default {
  isPublic({ state }, pageName) {
    if (pageName.toLowerCase() === 'home' || isTester.computed.isTester()) return true
    const page = state.pageProperties.pages.find(page => page.pageName === pageName)
    return page && Boolean(page.isPublic);
  },
  handleError: ({ commit }, err = { message: '', code: '99' }) => {
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
      err.code = err.response.status
    } else if (err.request) {
      /*
       * The request was made but no response was received, `err.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(err.request);
    } else {
      // Something happened in setting up the request and triggered an err
      console.log('Unexpected Error', err.message);
    }
    const errors = i18n.t('errors') || []
    let defaultMsg = errors['default']
    if (err.cause) {
      if (errors[err.cause]) {
        return commit('setModalOptions', { type: 'error', isActive: true, message: errors[err.cause] })
      } else {
        return commit('setModalOptions', { type: 'error', isActive: true, message: defaultMsg })
      }
    } else if (err.customMsg && err.code) {
      let message = defaultMsg + "<br>Code:: " + err.code + "<br>" + err.customMsg
      return commit('setModalOptions', { type: 'error', isActive: true, message: message })
    } else if (err.response?.data?.message) {
      return commit('setModalOptions', { type: 'error', isActive: true, message: errors[err.response.data.message.message] })
    } else if (err.code && ((err.message && errors[err.message]) || (err.message.message && errors[err.message.message]))) {
      const message = errors[err.message] || errors[err.message.message]
      return commit('setModalOptions', { type: 'error', isActive: true, message: message })
    } else {
      if (err.code == '404') {
        let msg = defaultMsg + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.code == '503') {
        let msg = errors['network'] + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.code == '502') {
        let msg = errors['network'] + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else if (err.message == 'Network Error') {
        let msg = errors['network'] + `<br>Code:: 50X`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      } else {
        let msg = defaultMsg + `<br>Code:: ${err.code}`
        return commit('setModalOptions', { type: 'error', isActive: true, message: msg })
      }
    }
  },

  setPageProperties: ({
    commit
  }, properties) => {
    commit('setPageProperties', properties);
  },

  refreshTempId: ({ state, dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/tempId`, { campaignId: getters.campaignId })
        .then(resp => {
          if (resp.data.response) {
            if (!location.pathname.includes("/iban-reupload")) resolve(resp.data.customerToken)
          } else {
            dispatch('handleError', { cause: 'customerToken', code: 90 })
            reject(resp.data.message)
          }
        })
        .catch(err => {
          dispatch('handleError', { cause: 'customerToken', code: 90 })
          reject(err)
        })
    })
  },
  setComponentStatus: ({ commit }, { component, status }) => {
    commit('setComponentStatus', { component, status });
  },
  sendFormData: ({ dispatch, state }, data) => {
    return new Promise((resolve, reject) => {

      if (window.nsId) {
        data.formData.nsId = window.nsId;
      }

      const campaignId = data.formData.campaignId
      const endPoint = `${APIUrl}/${campaignId}${data.endpoint}`

      axios.post(endPoint, { ...data.formData })
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { ...res.data, code: 96})
            return reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', { ...err.response.data, code: 96 })
          reject(err)
        })
    })
  },

  uploadProductPhotos({ getters, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('product', data.image)
      if(data.customerToken){
        formData.append ('customerToken', data.customerToken)
      } else formData.append('customerToken', getters.customerToken)
      // formData.append('campaignId', getters.campaignId)
      formData.append('receiptToken', data.receiptToken)

      axios.post(`${APIUrl}/${getters.campaignId}/uploadProductPhoto`, formData)
        .then(res => {
          if (res.data.response) {
            resolve(res.data)
          } else {
            dispatch('handleError', res.data)
            reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  generateCode({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      const { mobile } = data.formData
      const customerToken = state.customerToken
      const campaignId = state.campaignId

      const formData = {
        mobile,
        customerToken
      }

      axios.post(APIUrl + `/${campaignId}` + data.endpoint, formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { cause: 'generateCode' })
            return reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', { ...err.response.data, code: 97 })
          reject(err)
        })
    })
  },

  verifyCode({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      const { mobile, code } = data.formData
      const customerToken = state.customerToken
      const campaignId = state.campaignId

      const formData = {
        mobile,
        code,
        customerToken
      }

      axios.post(APIUrl + `/${campaignId}` + data.endpoint, formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError', { cause: 'wrongCode' })
            return reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', { cause: 'wrongCode' })
          reject(err)
        })
    })
  },

  sendProducts({ dispatch, state, getters }, data) {
    return new Promise((resolve, reject) => {
      // console.log("inside sendProducts::", data, state)
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = getters.campaignId

      axios.post(`${APIUrl}/${getters.campaignId}${data.endpoint}`, data.formData)
        .then(res => {
          if (res.data.response) {
            resolve(res.data)
          } else {
            dispatch('handleError', { customMsg: res.data.message })
            reject(res.data)
          }
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },

  uploadReceipt({ getters, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      if (data.receiptToken) {
        formData.append('receiptToken', data.receiptToken)
      }

      formData.append('userToken', data.customerToken)
      formData.append('receipt', data.image, 'receipt.png')
      formData.append('subCampaignId', getters.campaignId)
      formData.append('campaignId', data.campaignId)

      if (data.token) {
        formData.append('token', data.token)
      }
      return axios.post(`${data.apiUrl}`, formData)
        .then(resp => {
          if (resp.data.response !== 'ERR') {
            // If reference number is returned, set reference number
            if (resp.data.referenceNumber) {
              const data = {
                field: 'formData',
                value: { 'referenceNumber': resp.data.referenceNumber }
              }
              commit('setField', data)
            }

            commit('setReceiptToken', resp.data.receiptToken)
            resolve(resp.data.receiptToken);
          } else {
            dispatch('handleError', resp)
          }
        })
        .catch(err => {
          dispatch('handleError', { cause: 'invalidReceipt' })
          reject(err)
        })
    })
  },
  isUpdateIban: ({ dispatch, getters }, data) => {
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/${getters.campaignId}/ibanUpdateCheck`, { customerToken: data.customerToken })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch('handleError', err)
          reject(err)
        })
    })
  },
  check72Hours({ getters }, data) {
    const formData = {}
    formData.receiptToken = data
    formData.campaignId = getters.campaignId
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/${getters.campaignId}/check72Hours`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  lastStep({ state, dispatch }, params) {
    const customerToken = state.customerToken
    const campaignId = state.campaignId

    const formData = {
      ...params.extraData,
      customerToken,
      campaignId
    }

    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/${campaignId}${params.endpoint}`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch('handleError', { ...err.response.data, code: 93 })
          reject(err)
        })
    })
  },
  lastStepIframe({ state, dispatch }, endpoint) {
    const customerToken = state.customerToken
    const campaignId = state.campaignId

    const formData = {
      customerToken
    }

    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/${campaignId}${endpoint}`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch('handleError', { ...err.response.data, code: 93 })
          reject(err)
        })
    })
  },
  sendRequest({ getters, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/${getters.campaignId}${data.endpoint}`, data.formData)
        .then(resp => {
          if (resp.data.response)
            resolve(resp.data)
          else
            reject(resp.data)
        })
        .catch(err => {
          dispatch('handleError', err.response.data)
          reject(err)
        })
    })
  },
  checkEligibility({ dispatch, state }, data) {
    return new Promise((resolve, reject) => {

      const { endpoint } = data

      const customerToken = state.customerToken
      const campaignId = state.campaignId

      const formData = {
        customerToken,
        campaignId
      }

      axios.post(`${APIUrl}/${campaignId}${endpoint}`, formData)
        .then(resp => {
          if (resp.data.response)
            return resolve(resp.data)
          else {
            dispatch('handleError', { ...resp.data, code: 91 })
            reject(resp.data)
          }
        })
        .catch(err => {
          if(err.response.data.message.message === 'User is not eligible'){ //response is status 500 with no other identifier than the message, will be remediated
            reject({goToExpired: true})
          } else {
            dispatch('handleError', { ...err.response.data, code: 91 })
            reject(err.response.data)

          }
        })
    })
  },
  payOut({ state, dispatch }, endpoint) {

    const customerToken = state.customerToken
    const campaignId = state.campaignId

    const formData = {
      customerToken
    }

    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/${campaignId}${endpoint}`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch('handleError', { cause: 'usedIban' })
          reject(err)
        })
    })
  },
  searchProductByBarcode({ state, getters, commit }, { barcode, maxProductCount, endpoint }) {
    if (state.scannedProducts && state.scannedProducts.ean == barcode) {
      commit('setModalOptions', { type: 'error', isActive: true, message: i18n.t('You have already added this product.') });
      return false;
    }
    state.currentBarcode = barcode;
    const isAllowed = !state.scannedProducts
    if (/^[0-9]+$/.test(barcode) && isAllowed) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${APIUrl}/${getters.campaignId}${endpoint}`, {
            campaignId: getters.campaignId,
            barcode: barcode
          })
          .then(res => {
            if (res.data.response) {
              commit("setSearchStatus", 'success');
              commit("setProducts", res.data.product);
              resolve(res.data);
            } else {
              commit("setSearchStatus", 'not_found');
              commit('setErrorActivate', true)
              reject(res.data);
            }
          }).catch(err => {
            commit("setSearchStatus", 'not_found');
            reject(err)
          })
      });
    } else {
      if (!isAllowed) {
        console.log("max", maxProductCount)
        commit('setModalOptions', {
          type: 'error', isActive: true, message: i18n.t('You have already added the maximum number of products.')
        });
        commit("setSearchStatus", null);
      } else {
        commit("setSearchStatus", 'not_found');
        // The barcode is invalid
        commit('setScannerError', 'Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?')
        commit('setErrorActivate', true)
      }
    }
  },
  getActiveCampaigns({ getters, dispatch }, data) {
    return new Promise((resolve, reject) => {
      axios.get(`${APIUrl}/${data.marketingCampaignId}${data.endpoint}/${getters.consumerId}`)
        .then(resp => {
          if (resp.data.response)
            resolve(resp.data)
          else
            reject(resp.data)
        })
        .catch(err => {
          dispatch('handleError', err.response.data)
          reject(err)
        })
    })
  },
  async uploadAsset({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('name', '')
      formData.append('file', data)
      axios.post('https://template-v2.justsnap.eu/api/template/uploadAsset', formData, {
        headers: {
          "Content-Type": 'multipart/form-data'
        }
      })
      .then(res => {
        if (res.data.result === 'OK') resolve(res.data)
        else reject(res.data)
      })
      .catch(err => {
        dispatch('handleError', err.response.data)
        reject(err)
      })
    })
  }
}
